import { useContext, useEffect, useState } from "react";
import Filter from "../globale/Filter";
import classes from "./Gallery.module.css";
import DropDownFilter from "../globale/DropdownFiler";
import { DataContext } from "../../store/MainContext";
import { useLocation } from "react-router-dom";
import CarouselModal from "../globale/Modal";

const Gallery = () => {
    const { projects, cultures, designs } = useContext(DataContext);
    const [openCarousel, setOpenCarousel] = useState(null);
    const [activeCalture, setActiveCalture] = useState(false);
    const [activeDesign, setActiveDesign] = useState(false);
    const [activeItems, setActiveItems] = useState(projects);
    const { state } = useLocation();

    useEffect(() => {
        const data = state;
        if (data?.design) setActiveDesign(data.design);
        if (data?.culture) setActiveCalture(data.culture);
    }, [state]);

    useEffect(() => {
        let filteredItems = projects;

        filteredItems = !activeCalture
            ? filteredItems
            : filteredItems.filter((project) => {
                  for (let culture of project.cultures)
                      if (culture.id === activeCalture.id) return true;
                  return false;
              });

        filteredItems = !activeDesign
            ? filteredItems
            : filteredItems.filter((project) => {
                  for (let design of project.designs)
                      if (design.id === activeDesign.id) return true;
                  return false;
              });

        setActiveItems(filteredItems);
    }, [activeCalture, activeDesign, projects]);

    let activeItemsHtmlHolder = [];
    for (let i = 0; i < activeItems.length; i += 6) {
        activeItemsHtmlHolder.push(activeItems.slice(i, i + 6));
    }

    // Construct a dynamic image list for the carousel
    const carouselImages = activeItems
        .flatMap((project) => project.gallery)
        .map((img) => img.url || img);

    return (
        <div id="gallery" className="px-5 lg:px-10">
            <div className="flex flex-wrap items-center justify-between">
                <DropDownFilter
                    caltures={cultures}
                    activeCalture={activeCalture}
                    setActiveCalture={setActiveCalture}
                    className="ff-inter w-[29%] lg:w-full sm:max-w-[30%]"
                />
                <Filter
                    designs={designs}
                    activeDesign={activeDesign}
                    setActiveDesign={setActiveDesign}
                    className="w-[70%] lg:w-full sm:max-w-[70%] flex justify-end"
                />
            </div>

            {/* Carousel Modal */}
            {openCarousel !== null && (
                <CarouselModal
                    images={carouselImages}
                    isOpen={openCarousel !== null}
                    onClose={() => setOpenCarousel(null)}
                    initialIndex={openCarousel}
                />
            )}

            {activeItemsHtmlHolder.map((items, index) => (
                <main key={index} className={`${classes.main} my-6`}>
                    {items.map((project, idx) => (
                        <figure
                            key={idx}
                            onClick={() =>
                                project.gallery.length > 0 &&
                                setOpenCarousel(
                                    carouselImages.indexOf(
                                        project.gallery[0]?.url ||
                                            project.gallery[0]
                                    )
                                )
                            }
                            className={`${
                                project.gallery.length > 0
                                    ? "cursor-pointer"
                                    : ""
                            }`}
                        >
                            <img src={project.image} alt={project.image} />
                        </figure>
                    ))}
                </main>
            ))}
        </div>
    );
};

export default Gallery;
