/** @format */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import image from "../../assets/images/servicesSection.jpeg";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import classes from "./ServicesSection.module.css";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { DataContext } from "../../store/MainContext";
import { Link } from "react-router-dom";

const ServicesSection = ({ data }) => {
    const { t } = useTranslation();

    const { services } = useContext(DataContext);
    const servicesCat = services.map((service) => ({
        name: service.name,
        url: `/services`,
    }));

    return (
        <div className={`sm:py-[40px]`} style={{ backgroundColor: "#171717" }}>
            <div
                className={`2xl:container flex justify-start gap-[30px] sm:gap-[50px] flex-col lg:flex-row ${classes.container} max-lg:p-0`}
            >
                <div
                    className={`${classes.sectionImage}`}
                    style={{
                        backgroundImage: `url(${data.servicesImage || image})`,
                    }}
                ></div>
                <div className={`${classes["services-list"]}`}>
                    <h2>{data.servicesHead || t("Our Services")}</h2>
                    <ul className="">
                        {servicesCat.map((service, index) => (
                            <li key={index}>
                                <Link to={service.url}>
                                    <FontAwesomeIcon icon={faArrowLeft} />
                                    <span>{service.name}</span>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default ServicesSection;

