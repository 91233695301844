/** @format */

import logo from "../assets/images/loading.gif";
const PreLoader = () => {
    return (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-[1000] bg-white">
            <img
                src={logo}
                alt="Loading..."
                className="w-1/3 max-w-[50%] h-auto"
            />
        </div>
    );
};

export default PreLoader;
